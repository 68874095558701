import { defineStore } from "pinia";
import { useNuxtApp } from "#app";

export const useModalStore = defineStore("modal", {
  state: () => ({
    modals: [],
  }),
  actions: {
    openModal({
      title,
      content,
      size,
      okButtonName,
      cancelButtonName,
      onOk,
      onCancel,
      okButtonOnly,
    }) {
      const newModal = {
        isVisible: false,
        title:
          title ||
          useNuxtApp().vueApp.$i18n?.global.t("common.modal.title.confirm"),
        okButtonName:
          okButtonName ||
          useNuxtApp().vueApp.$i18n?.global.t("common.button.ok"),
        cancelButtonName:
          cancelButtonName ||
          useNuxtApp().vueApp.$i18n?.global.t("common.button.cancel"),
        okButtonHandler: onOk,
        okButtonOnly: okButtonOnly !== undefined ? okButtonOnly : false,
        cancelButtonHandler: onCancel,
        content,
        size,
      };
      this.modals = [...this.modals, newModal];
      nextTick(() => {
        this.modals.pop();
        newModal.isVisible = true;
        this.modals = [...this.modals, newModal];
      });
    },
    initializeModal(modal) {
      modal.isVisible = false;
      modal.title = "";
      modal.content = "";
      modal.size = undefined;
      modal.okButtonOnly = false;
      modal.okButtonName = "";
      modal.cancelButtonName = "";
      modal.okButtonHandler = undefined;
      modal.cancelButtonHandler = undefined;
    },
    closeModal() {
      if (this.modals.length > 0) {
        const modal = this.modals.pop();
        if (modal.cancelButtonHandler) {
          modal.cancelButtonHandler(() => {
            this.initializeModal(modal);
          });
        } else {
          this.initializeModal(modal);
        }
      }
      if (this.modals.length === 0) {
        document.body.classList.remove("modal-open");
        document.body.style.paddingRight = "";
        document.body.style.overflow = "";
      }
    },
    confirmModal() {
      if (this.modals.length > 0) {
        const modal = this.modals.pop();
        if (modal.okButtonHandler) {
          modal.okButtonHandler(() => {
            this.initializeModal(modal);
          });
        } else {
          this.initializeModal(modal);
        }
      }
      if (this.modals.length === 0) {
        document.body.classList.remove("modal-open");
        document.body.style.paddingRight = "";
        document.body.style.overflow = "";
      }
    },
  },
});
